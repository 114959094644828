
import { share } from '@/common/jsBridge.api'
import { keymap } from '@/config'
import { translate } from '@/i18n'
import { makeShareQuery, makeShareUrl } from '@/pages/invite/invite'
import { localGet } from 'essential/store/localStore'
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import { readShareInfo } from '@/pages/invite/invite.api'

export default defineComponent({
  name: 'InviteShare',
  components: { PageWithHeader, Pic, Button },
  setup () {
    const invitedList = ref<Array<string | null>>([])

    readShareInfo().then(resp => {
      invitedList.value = resp.invitatedUsers || []
    })

    return {
      share () {
        share({
          title: translate('sharelink_27', 'The best mobile trading platform.'),
          url: `${makeShareUrl()}#/link/invite?${makeShareQuery()}&name=${localGet(
            keymap.user.nickName)}`,
          image: `${location.origin}/img/logo@2x.png`,
        })
      },
      invitedList,
      steps: [
        {
          icon: 'invite/step1',
          label: 'invite_8',
          labelDefault: 'You share the link to Facebook, WhatsApp etc',
        },
        {
          icon: 'invite/step2',
          label: 'invite_9',
          labelDefault: 'Friend signs up via the link you shared',
        },
        {
          icon: 'invite/step3',
          label: 'invite_10',
          labelDefault: 'Friend deposits in WeTrade, you both get rewarded',
        },
      ],
    }
  },
})
