/**
* @author zjc[beica1@outook.com]
* @date 2021/8/26 11:39
* @description
*   InviteShare.vue of WeTrade
*/
<template>
  <PageWithHeader class="pt-8" header-class="bg-primary c-white">
    <template #title>
      <t path="invite_2">Invite Friends</t>
    </template>
    <div class="p-r c-white">
      <Pic src="invite/inviteImg1" class="img" />
      <t as="p" path="invite_3" class="title f-bold">Invite 1 friend to trade</t>
    </div>
    <div class="p-r c-white f-bold t-center" style="font-size: 14px; line-height: 14px;">
      <Pic src="invite/inviteImg2" class="img" />
      <t as="div" path="invite_4" class="info df-cross-center left px-6">You get</t>
      <t as="div" path="invite_6" class="info df-cross-center right px-6">You friend get</t>
    </div>
    <div class="p-r c-white">
      <Pic src="invite/inviteImg3" class="img" />
      <div class="info df-cross-center left">
        <div>
          <p class="f-xl f-bold df-center" style="line-height: 32px; align-items: flex-end;">
            <span style="line-height: 26px;">$</span>
            <span style="font-size: 32px;">5</span>
          </p>
          <t as="p" path="invite_5" class="f-md">coupon</t>
        </div>
      </div>
      <div class="info df-cross-center right">
        <div>
          <p class="f-xl f-bold df-center" style="line-height: 32px; align-items: flex-end;">
            <span style="line-height: 26px;">$</span>
            <span style="font-size: 32px;">5</span>
          </p>
          <t as="p" path="invite_7" class="f-md">coupon</t>
        </div>
      </div>
    </div>
    <div class="p-32">
      <div class="pb-32" style="line-height: 20px; font-size: 14px;">
        <template v-for="(step, index) in steps" :key="index">
          <div class="df-middle">
            <Pic :src="step.icon" width="60" height="48" class="pr-16 pl-6" />
            <t as="p" class="flex-1" :path="step.label">{{step.labelDefault}}</t>
          </div>
          <Pic v-if="index < steps.length - 1" src="invite/arrow" width="16" height="16"
               class="my-8 ml-32" />
        </template>
      </div>
      <Button class="btn primary block df-cross-center share-btn f-bold f-lg" @click="share">
        <t path="invite_11" #="{td}" custom>{{td || 'Share the link'}}</t>
        <Pic src="invite/share" width="24" height="24" class="ml-6" />
      </Button>
      <div class="mt-32 t-center">
        <p class="c-title f-sm mb-16 df-cross-center">
          <span>—</span>
          <t path="invite_12" class="mr-6" :args="{n: invitedList.length}"></t>
          <span>—</span>
        </p>
        <div>
          <p class="mb-8 bg-background invited-list" v-for="item in invitedList" :key="item">
            {{item}}
          </p>
        </div>
      </div>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { share } from '@/common/jsBridge.api'
import { keymap } from '@/config'
import { translate } from '@/i18n'
import { makeShareQuery, makeShareUrl } from '@/pages/invite/invite'
import { localGet } from 'essential/store/localStore'
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import { readShareInfo } from '@/pages/invite/invite.api'

export default defineComponent({
  name: 'InviteShare',
  components: { PageWithHeader, Pic, Button },
  setup () {
    const invitedList = ref<Array<string | null>>([])

    readShareInfo().then(resp => {
      invitedList.value = resp.invitatedUsers || []
    })

    return {
      share () {
        share({
          title: translate('sharelink_27', 'The best mobile trading platform.'),
          url: `${makeShareUrl()}#/link/invite?${makeShareQuery()}&name=${localGet(
            keymap.user.nickName)}`,
          image: `${location.origin}/img/logo@2x.png`,
        })
      },
      invitedList,
      steps: [
        {
          icon: 'invite/step1',
          label: 'invite_8',
          labelDefault: 'You share the link to Facebook, WhatsApp etc',
        },
        {
          icon: 'invite/step2',
          label: 'invite_9',
          labelDefault: 'Friend signs up via the link you shared',
        },
        {
          icon: 'invite/step3',
          label: 'invite_10',
          labelDefault: 'Friend deposits in WeTrade, you both get rewarded',
        },
      ],
    }
  },
})
</script>

<style scoped lang="scss">
.img {
  display: block;
  width: 100%;
}

.title {
  position: absolute;
  left: 20%;
  right: 20%;
  top: 24px;
  font-size: 28px;
  text-align: center;
  line-height: 32px;
}

.share-btn {
  ::v-deep(span) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.invited-list {
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
}

.info {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 32%;
}

.left {
  left: 10.6%;
}

.right {
  right: 10.6%;
}

@media only screen and (max-width: 320px) {
  .title {
    left: 20%;
    right: 20%;
    top: 20px;
    font-size: 24px;
    line-height: 28px;
  }
}
</style>
